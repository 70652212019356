import { StateCreator } from "zustand";

import { ITimelineEvent, ITrackingTimeline } from "~/types";

export interface TTrackingStoreState {
  trackingTimeline: ITrackingTimeline;
  addTrackingData: (newData: ITimelineEvent, isSubmitted: boolean) => void;
  clearTrackingTimeline: () => void;
}

// Define the initial state for trackingTimeline
const initialTrackingTimeline: ITrackingTimeline = {
  startTime: new Date(),
  timeline: [],
};

const checkAndPushTimeline = (
  timeline: ITimelineEvent[],
  newData: ITimelineEvent,
  isSubmitted: boolean
): ITimelineEvent[] => {
  if (timeline.length === 0 || isSubmitted) {
    return [...timeline, newData];
  }

  const latestData = timeline[timeline.length - 1];

  if (latestData.projectId !== newData.projectId) {
    return [...timeline, newData];
  }

  return timeline;
};

export const createTrackingState: StateCreator<
  TTrackingStoreState,
  [],
  [],
  TTrackingStoreState
> = (set) => ({
  trackingTimeline: {
    startTime: new Date(),
    timeline: [],
  },
  addTrackingData: (newData: ITimelineEvent, isSubmitted: boolean) =>
    set((state) => ({
      trackingTimeline: {
        ...state.trackingTimeline,
        timeline: checkAndPushTimeline(
          state.trackingTimeline.timeline || [],
          newData,
          isSubmitted
        ),
      },
    })),
  clearTrackingTimeline: () =>
    set(() => ({
      trackingTimeline: initialTrackingTimeline,
    })),
});
