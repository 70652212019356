export enum EResidency {
  SINGAPOREAN = "Singaporean",
  PERMANENT_RESIDENT = "Permanent Resident",
  FOREIGNER = "Foreigner",
  CORPORATE_ENTITY = "Corporate Entity",
}

export enum EPropertiesOwned {
  ZERO = "zero",
  ONE = "one",
  GREATER_THAN_TWO = "greater_than_two",
}

export enum EHousingLoan {
  ZERO = "zero",
  ONE = "one",
  GREATER_THAN_TWO = "greater_than_two",
}

export enum EMortgageType {
  HDB = "HDB",
  CONDO = "Condo",
  BUC = "BUC",
}
