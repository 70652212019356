import React, { useEffect, useState } from "react";

import { ICurrencyInputProps } from "./type";
import { Input } from "../Input";

const CurrencyInput: React.FC<ICurrencyInputProps> = ({
  value,
  min,
  max,
  className = "",
  onChange,
}) => {
  const [localValue, setLocalValue] = useState<string>("");

  useEffect(() => {
    if (typeof value === "number") {
      const formattedValue = formatValue(value.toString());
      if (localValue !== formattedValue) {
        setLocalValue(formattedValue);
      }
    }
  }, [value]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;

    // Allow control keys such as backspace, delete, arrow keys
    const controlKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Tab",
    ];

    // Prevent users from entering letters and other non-numeric characters
    if (!/^\d$/.test(key) && !controlKeys.includes(key)) {
      event.preventDefault();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    // Format the input value
    const formattedValue = formatValue(value);

    // Remove commas for numeric comparison
    const numericValue = formattedValue.replace(/,/g, "");

    // Convert to number for comparison
    const numberValue = parseFloat(numericValue) || 0;

    // Apply min and max constraints
    if (
      typeof min === "number" &&
      typeof max === "number" &&
      (numberValue > max || numberValue < min)
    ) {
      return;
    }

    setLocalValue(formattedValue);
    onChange(numberValue);
  };

  const formatValue = (value: string) => {
    const parts = value.split(".");
    const integerPart = parts[0]?.replace(/\D/g, "");
    const decimalPart = parts[1] ? `.${parts[1]}` : "";
    const formattedIntegerValue = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
    return formattedIntegerValue + decimalPart;
  };

  return (
    <Input
      type="text"
      placeholder="0"
      autoComplete="off"
      className={className}
      value={localValue}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
    />
  );
};

export default CurrencyInput;
