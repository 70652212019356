import { StateCreator } from "zustand";

export type TAppointmentState = {
    appointmentOpen: boolean;
    setAppointmentOpen: (appointmentOpen: boolean) => void;
};

export const createAppointmentState: StateCreator<
    TAppointmentState,
    [],
    [],
    TAppointmentState
> = (set) => ({
    appointmentOpen: false, // Correct property name
    setAppointmentOpen: (appointmentOpen) =>
        set(() => ({ appointmentOpen })), // Update the state using set
});
