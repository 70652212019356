import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import "animate.css";
import "./styles.css";

import { IMorgageCalculatorProps } from "./MorgageCalculator.types";

import Button from "~/components/atoms/Button";
import { CurrencyInput } from "~/components/atoms/CurrencyInput";
import { Icon } from "~/components/atoms/Icons";
import { Input } from "~/components/atoms/Input";
import { RangeSlider } from "~/components/atoms/RangeSlider";
import Typo from "~/components/atoms/Typo";
import PaymentSummary from "~/components/molecules/PaymentSummary";
import { TPaymentStage } from "~/entities/mortgage";
import useAppState from "~/stores";
import { cn } from "~/utilities/cn";
import {
  HOUSING_LOAN,
  MAX_INTERESTED_RATE,
  MAX_TENURE,
  MIN_INTERESTED_RATE,
  MIN_TENURE,
  PAYMENT_STAGES,
  PROPERTIES_OWNED,
  RESIDENCY,
} from "~/utilities/constants/mortgage";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import {
  EHousingLoan,
  EPropertiesOwned,
  EResidency,
} from "~/utilities/enums/mortgage";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import { calculateBSD, getPaymentStages } from "~/utilities/helpers/mortgage";
import { formatCurrency } from "~/utilities/helpers/string";

type TResult = {
  loanAmount: number;
  bookingFee: number;
  exerciseFee: number;
  bookingFeePercent: number;
  exerciseFeePercent: number;
  buyerStampDuty: number;
  totalInitital: number;
  additionalBuyerStampDuty: number;
  paymentStages: TPaymentStage[];
  ltv: number;
  submitted: boolean;
};

export type TMortgageCalculatorFormData = {
  price: number;
  ltv: number;
  interestRate: number;
  tenure: number;
  residency: string;
  propertiesOwned: string;
  housingLoan: string;
};

export const DEFAULT_MORTGAGE_CALCULATOR_FORM_VALUE: TMortgageCalculatorFormData =
  {
    price: 0,
    interestRate: 0,
    tenure: MIN_TENURE,
    housingLoan: "",
    propertiesOwned: "",
    residency: "",
    ltv: 0,
  };

const DEFAULT_RESULT: TResult = {
  loanAmount: 0,
  bookingFee: 0,
  bookingFeePercent: 0,
  exerciseFeePercent: 0,
  exerciseFee: 0,
  buyerStampDuty: 0,
  totalInitital: 0,
  additionalBuyerStampDuty: 0,
  paymentStages: PAYMENT_STAGES,
  ltv: 0,
  submitted: false,
};

const MorgageCalculator: React.FC<IMorgageCalculatorProps> = () => {
  const { t } = useTranslation();
  const {
    selectedMortgageRate,
    mortgageCalculatorFormData,
    setMortgageCalculatorFormData,
  } = useAppState((state) => state);
  const calculatorResultRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<TResult>(DEFAULT_RESULT);
  const handleSubmit = async () => {
    const loanAmount = Number(
      (
        ((mortgageCalculatorFormData?.ltv || 0) *
          (mortgageCalculatorFormData?.price || 0)) /
        100
      ).toFixed(2)
    );
    const bookingFee = Number(
      (
        ((PROPERTIES_OWNED?.[
          mortgageCalculatorFormData?.propertiesOwned as EPropertiesOwned
        ]?.["bookingFee"] || 0) *
          (mortgageCalculatorFormData?.price || 0)) /
        100
      ).toFixed(2)
    );
    const exerciseFee = Number(
      (
        ((PROPERTIES_OWNED?.[
          mortgageCalculatorFormData?.propertiesOwned as EPropertiesOwned
        ]?.["exerciseFee"] || 0) *
          (mortgageCalculatorFormData?.price || 0)) /
        100
      ).toFixed(2)
    );
    const buyerStampDuty = calculateBSD(
      Number(mortgageCalculatorFormData?.price || 0) || 0
    );
    const additionalBuyerStampDuty = Number(
      (
        ((RESIDENCY?.[mortgageCalculatorFormData?.residency as EResidency]?.[
          "additionalBSD"
        ]?.[mortgageCalculatorFormData?.propertiesOwned as EPropertiesOwned] ||
          0) *
          (mortgageCalculatorFormData?.price || 0)) /
        100
      ).toFixed(2)
    );
    const totalInitital =
      bookingFee + exerciseFee + buyerStampDuty + additionalBuyerStampDuty;

    const paymentStages: TPaymentStage[] = getPaymentStages(
      Number(mortgageCalculatorFormData?.price) || 0,
      Number(mortgageCalculatorFormData?.interestRate) || 0,
      Number(mortgageCalculatorFormData?.tenure) || 0
    );
    setLoading(true);

    setResult({
      paymentStages,
      totalInitital,
      bookingFee,
      exerciseFee,
      additionalBuyerStampDuty,
      buyerStampDuty,
      loanAmount,
      exerciseFeePercent:
        PROPERTIES_OWNED?.[
          mortgageCalculatorFormData?.propertiesOwned as EPropertiesOwned
        ]?.["exerciseFee"] || 0,
      bookingFeePercent:
        PROPERTIES_OWNED?.[
          mortgageCalculatorFormData?.propertiesOwned as EPropertiesOwned
        ]?.["bookingFee"] || 0,
      ltv: mortgageCalculatorFormData?.ltv || 0,
      submitted: true,
    });
    setTimeout(() => {
      calculatorResultRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setLoading(false);
    }, 2000); // 2 seconds delay
  };

  useEffect(() => {
    setMortgageCalculatorFormData({
      ...mortgageCalculatorFormData,
      interestRate: selectedMortgageRate,
    });
  }, [selectedMortgageRate]);

  const disabled =
    _.isEmpty(mortgageCalculatorFormData?.housingLoan) ||
    mortgageCalculatorFormData?.interestRate === 0 ||
    mortgageCalculatorFormData?.price === 0 ||
    _.isEmpty(mortgageCalculatorFormData?.propertiesOwned) ||
    _.isEmpty(mortgageCalculatorFormData?.residency) ||
    mortgageCalculatorFormData?.tenure === 0;

  return (
    <div className="flex flex-col gap-12">
      <div className="flex w-9/12 flex-col gap-12 lg:w-full">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-3">
            <Typo
              tag={ETypoTag.P}
              variant={ETypoVariant.SUB_TITLE_16}
              color={ETypoColor.TEXT}
              className="capitalize"
            >
              {t("mortgage_calculator.purchase_price")} ($)
            </Typo>
            <div className="flex w-full items-center gap-2 border-b-[0.5px] border-color pb-3">
              <Typo
                tag={ETypoTag.SPAN}
                variant={ETypoVariant.BODY_TITLE_16}
                color={ETypoColor.TEXT}
              >
                $
              </Typo>
              <CurrencyInput
                value={mortgageCalculatorFormData?.price || 0}
                onChange={(value: number) => {
                  const temp = Number(value) || 0;
                  setMortgageCalculatorFormData({
                    ...mortgageCalculatorFormData,
                    price: temp,
                  });
                }}
                className="w-full rounded-none border-none bg-transparent p-0 text-header-16 font-bold text-color outline-none"
              />
            </div>
          </div>

          <div className="flex items-center justify-start gap-6">
            <div className="w-1/3">
              <div className="flex flex-col gap-3">
                <Typo
                  tag={ETypoTag.P}
                  variant={ETypoVariant.SUB_TITLE_16}
                  color={ETypoColor.TEXT}
                  className="capitalize"
                >
                  {t("mortgage_calculator.tenure")} (Yrs)
                </Typo>
                <div className="flex w-full items-center gap-2 border-b-[0.5px] border-color pb-3">
                  <Typo
                    tag={ETypoTag.P}
                    variant={ETypoVariant.BODY_TITLE_16}
                    color={ETypoColor.TEXT}
                  >
                    Yrs
                  </Typo>
                  <Input
                    type="number"
                    placeholder="0"
                    min={MIN_TENURE}
                    max={MAX_TENURE}
                    autoComplete="off"
                    value={mortgageCalculatorFormData?.tenure || 0}
                    onChange={(e) => {
                      const temp = Number(e?.currentTarget?.value) || 0;
                      setMortgageCalculatorFormData({
                        ...mortgageCalculatorFormData,
                        tenure: temp,
                      });
                    }}
                    className="w-full rounded-none border-none bg-transparent p-0 text-header-16 font-bold text-color outline-none"
                  />
                </div>
              </div>
            </div>
            <div className="w-2/3">
              <RangeSlider
                min={MIN_TENURE}
                max={MAX_TENURE}
                value={mortgageCalculatorFormData?.tenure || 0}
                onChange={(value) => {
                  const temp = Number(value) || 0;
                  setMortgageCalculatorFormData({
                    ...mortgageCalculatorFormData,
                    tenure: temp,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex items-center justify-start gap-6">
            <div className="w-1/3">
              <div className="flex flex-col gap-3">
                <Typo
                  tag={ETypoTag.P}
                  variant={ETypoVariant.SUB_TITLE_16}
                  color={ETypoColor.TEXT}
                  className="capitalize"
                >
                  {t("mortgage_calculator.interest_rate")} (%)
                </Typo>
                <div className="flex w-full items-center gap-2 border-b-[0.5px] border-color pb-3">
                  <Typo
                    tag={ETypoTag.SPAN}
                    variant={ETypoVariant.BODY_TITLE_16}
                    color={ETypoColor.TEXT}
                  >
                    %
                  </Typo>
                  <Input
                    type="number"
                    placeholder="0"
                    min={MIN_INTERESTED_RATE}
                    max={MAX_INTERESTED_RATE}
                    autoComplete="off"
                    value={mortgageCalculatorFormData?.interestRate || 0}
                    onChange={(e) => {
                      const temp = Number(e?.currentTarget?.value) || 0;
                      setMortgageCalculatorFormData({
                        ...mortgageCalculatorFormData,
                        interestRate: temp,
                      });
                    }}
                    className="w-full rounded-none border-none bg-transparent p-0 text-header-16 font-bold text-color outline-none"
                  />
                </div>
              </div>
            </div>
            <div className="w-2/3">
              <RangeSlider
                min={MIN_INTERESTED_RATE}
                max={MAX_INTERESTED_RATE}
                step={0.01}
                value={mortgageCalculatorFormData?.interestRate || 0}
                onChange={(value: number) =>
                  setMortgageCalculatorFormData({
                    ...mortgageCalculatorFormData,
                    interestRate: value,
                  })
                }
              />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <Typo
              tag={ETypoTag.P}
              variant={ETypoVariant.SUB_TITLE_16}
              color={ETypoColor.TEXT}
              className="capitalize"
            >
              {t("mortgage_calculator.residency")}
            </Typo>
            <div className="flex flex-wrap items-center justify-start gap-6">
              {Object.keys(RESIDENCY).map((e) => (
                <Button
                  key={`button-${e}`}
                  variant="tab"
                  type="button"
                  className={cn(
                    "w-fit !rounded-[21.5px] !border !border-solid px-[23px] py-3 text-sub-title-16 font-bold capitalize lg:text-sub-title-14",
                    e === mortgageCalculatorFormData?.residency
                      ? "border-mediumTurquoise !bg-mediumTurquoise !text-white"
                      : "border border-gableGreen5 !bg-gableGreen5 !text-color"
                  )}
                  onClick={() =>
                    setMortgageCalculatorFormData({
                      ...mortgageCalculatorFormData,
                      residency: e,
                    })
                  }
                >
                  {t(
                    `mortgage_calculator.${RESIDENCY?.[e as EResidency]?.label}`
                  )}
                </Button>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <Typo
              tag={ETypoTag.P}
              variant={ETypoVariant.SUB_TITLE_16}
              color={ETypoColor.TEXT}
              className="capitalize"
            >
              {t("mortgage_calculator.properties_owned")}
            </Typo>
            <div className="grid grid-cols-3 gap-6">
              {Object.keys(PROPERTIES_OWNED).map((e) => (
                <Button
                  key={`button-${e}`}
                  variant="tab"
                  type="button"
                  className={cn(
                    "w-full !rounded-[21.5px] !border !border-solid px-[18px] py-3 text-sub-title-16 font-bold capitalize lg:text-sub-title-14",
                    e === mortgageCalculatorFormData?.propertiesOwned
                      ? "border-mediumTurquoise !bg-mediumTurquoise !text-white"
                      : "border border-gableGreen5 !bg-gableGreen5 !text-color"
                  )}
                  onClick={() =>
                    setMortgageCalculatorFormData({
                      ...mortgageCalculatorFormData,
                      propertiesOwned: e,
                    })
                  }
                >
                  {PROPERTIES_OWNED?.[e as EPropertiesOwned]?.label}
                </Button>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <Typo
              tag={ETypoTag.P}
              variant={ETypoVariant.SUB_TITLE_16}
              color={ETypoColor.TEXT}
              className="capitalize"
            >
              {t("mortgage_calculator.your_current_housing_loan")}
            </Typo>
            <div className="grid grid-cols-3 gap-6">
              {Object.keys(HOUSING_LOAN).map((e) => (
                <Button
                  key={`button-${e}`}
                  variant="tab"
                  type="button"
                  className={cn(
                    "w-full !rounded-[21.5px] !border !border-solid px-[18px] py-3 text-sub-title-16 font-bold capitalize lg:text-sub-title-14",
                    e === mortgageCalculatorFormData?.housingLoan
                      ? "border-mediumTurquoise !bg-mediumTurquoise !text-white"
                      : "border border-gableGreen5 !bg-gableGreen5 !text-color"
                  )}
                  onClick={() => {
                    setMortgageCalculatorFormData({
                      ...mortgageCalculatorFormData,
                      housingLoan: e,
                      ltv: HOUSING_LOAN[e as EHousingLoan]["ltv"],
                    });
                  }}
                >
                  {HOUSING_LOAN?.[e as EHousingLoan]?.label}
                </Button>
              ))}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <Button
            variant="tab"
            type="button"
            className={cn(
              "flex-start flex w-[128px] gap-[10px] !rounded-[21.5px] !border !border-solid px-6 py-3",
              "border-color !bg-transparent  !text-color"
            )}
            onClick={() => {
              setMortgageCalculatorFormData(
                DEFAULT_MORTGAGE_CALCULATOR_FORM_VALUE
              );
              setResult(DEFAULT_RESULT);
            }}
          >
            <Icon TypeIcon={EIcon.ResetIcon} height={18} width={18} />
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.SUB_TITLE_16}
              color={ETypoColor.TEXT}
              className="font-bold capitalize"
            >
              {t("reset")}
            </Typo>
          </Button>
          <Button
            variant="tab"
            type="button"
            className={cn(
              "w-[160px] !rounded-[21.5px] !border !border-solid px-[18px] py-3 text-sub-title-16 font-bold lg:text-sub-title-14",
              "border-color !bg-color capitalize !text-white disabled:pointer-events-auto",
              disabled ? "cursor-not-allowed" : ""
            )}
            disabled={disabled}
            onClick={() => {
              if (!disabled) handleSubmit();
            }}
            isLoading={loading}
          >
            {loading ? `${t("calculating")} ...` : t("submit")}
          </Button>
        </div>
      </div>
      <hr className="border-color" />
      <div ref={calculatorResultRef}>
        {result?.submitted && !loading && (
          <>
            <div className="flex flex-col gap-12">
              <div className="flex items-start justify-between">
                <Typo
                  tag={ETypoTag.P}
                  variant={ETypoVariant.SUB_TITLE_20}
                  color={ETypoColor.TEXT}
                  className="font-bold uppercase"
                >
                  {t("mortgage_calculator.progressive_payments_calculator")}
                </Typo>
                <Typo
                  tag={ETypoTag.P}
                  variant={ETypoVariant.SUB_TITLE_20}
                  color={ETypoColor.TEXT}
                  className="font-bold"
                >
                  {t("mortgage_calculator.loan_amount")} ({result?.ltv}%):{" "}
                  {formatCurrency(result?.loanAmount)}
                </Typo>
              </div>
              <div className="flex flex-row gap-[120px] lg:flex-col lg:gap-6">
                <div className="flex w-3/12 flex-col gap-8 lg:w-full">
                  <Typo
                    tag={ETypoTag.P}
                    variant={ETypoVariant.SUB_TITLE_20}
                    color={ETypoColor.TEXT}
                    className="font-bold"
                  >
                    1. {t("mortgage_calculator.initial_payment")}
                  </Typo>
                  <div className="flex flex-col gap-6">
                    <PaymentSummary totalInitial={result?.totalInitital || 0} />

                    <div className="flex flex-col gap-3">
                      <div className="animate__animated animate__fadeInDown custom-delay-1 flex flex-col gap-3 border-b border-color pb-3">
                        <div className="flex items-center justify-between">
                          <Typo
                            tag={ETypoTag.P}
                            variant={ETypoVariant.BODY_TITLE_16}
                            color={ETypoColor.TEXT}
                          >
                            {t("mortgage_calculator.booking_fee")}
                          </Typo>
                          <Typo
                            tag={ETypoTag.P}
                            variant={ETypoVariant.BODY_TITLE_16}
                            color={ETypoColor.TEXT}
                          >
                            {result?.bookingFeePercent}%
                          </Typo>
                        </div>
                        <div className="flex w-full items-center gap-2">
                          <Typo
                            tag={ETypoTag.SPAN}
                            variant={ETypoVariant.BODY_TITLE_16}
                            color={ETypoColor.TEXT}
                          >
                            $
                          </Typo>
                          <Typo
                            tag={ETypoTag.SPAN}
                            variant={ETypoVariant.BODY_TITLE_16}
                            color={ETypoColor.TEXT}
                            className="font-bold"
                          >
                            {formatCurrency(result?.bookingFee || 0)}
                          </Typo>
                        </div>
                      </div>

                      <div className="animate__animated animate__fadeInDown custom-delay-2 flex flex-col gap-3 border-b border-color pb-3">
                        <div className="flex items-center justify-between">
                          <Typo
                            tag={ETypoTag.P}
                            variant={ETypoVariant.BODY_TITLE_16}
                            color={ETypoColor.TEXT}
                          >
                            {t("mortgage_calculator.exercise_fee")}
                          </Typo>
                          <Typo
                            tag={ETypoTag.P}
                            variant={ETypoVariant.BODY_TITLE_16}
                            color={ETypoColor.TEXT}
                          >
                            {result?.exerciseFeePercent}%
                          </Typo>
                        </div>
                        <div className="flex w-full items-center gap-2">
                          <Typo
                            tag={ETypoTag.SPAN}
                            variant={ETypoVariant.BODY_TITLE_16}
                            color={ETypoColor.TEXT}
                          >
                            $
                          </Typo>
                          <Typo
                            tag={ETypoTag.SPAN}
                            variant={ETypoVariant.BODY_TITLE_16}
                            color={ETypoColor.TEXT}
                            className="font-bold"
                          >
                            {formatCurrency(result?.exerciseFee)}
                          </Typo>
                        </div>
                      </div>

                      <div className="animate__animated animate__fadeInDown custom-delay-3 flex flex-col gap-3 border-b border-color pb-3">
                        <div className="flex items-center justify-between">
                          <Typo
                            tag={ETypoTag.P}
                            variant={ETypoVariant.BODY_TITLE_16}
                            color={ETypoColor.TEXT}
                          >
                            {t("mortgage_calculator.buyer_stamp_duty")}
                          </Typo>
                        </div>
                        <div className="flex w-full items-center gap-2">
                          <Typo
                            tag={ETypoTag.SPAN}
                            variant={ETypoVariant.BODY_TITLE_16}
                            color={ETypoColor.TEXT}
                          >
                            $
                          </Typo>
                          <Typo
                            tag={ETypoTag.SPAN}
                            variant={ETypoVariant.BODY_TITLE_16}
                            color={ETypoColor.TEXT}
                            className="font-bold"
                          >
                            {formatCurrency(result?.buyerStampDuty)}
                          </Typo>
                        </div>
                      </div>

                      <div className="animate__animated animate__fadeInDown custom-delay-4 flex flex-col gap-3 border-b border-color pb-3">
                        <div className="flex items-center justify-between">
                          <Typo
                            tag={ETypoTag.P}
                            variant={ETypoVariant.BODY_TITLE_16}
                            color={ETypoColor.TEXT}
                          >
                            {t(
                              "mortgage_calculator.additional_buyer_stamp_duty"
                            )}
                          </Typo>
                        </div>
                        <div className="flex w-full items-center gap-2">
                          <Typo
                            tag={ETypoTag.SPAN}
                            variant={ETypoVariant.BODY_TITLE_16}
                            color={ETypoColor.TEXT}
                          >
                            $
                          </Typo>
                          <Typo
                            tag={ETypoTag.SPAN}
                            variant={ETypoVariant.BODY_TITLE_16}
                            color={ETypoColor.TEXT}
                            className="font-bold"
                          >
                            {formatCurrency(result?.additionalBuyerStampDuty)}
                          </Typo>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex w-9/12 flex-col gap-8 lg:w-full">
                  <Typo
                    tag={ETypoTag.P}
                    variant={ETypoVariant.SUB_TITLE_20}
                    color={ETypoColor.TEXT}
                    className="font-bold"
                  >
                    2.{" "}
                    {t(
                      "mortgage_calculator.subsequent_payment_after_each_stage"
                    )}
                  </Typo>
                  <div className="flex flex-col gap-2 overflow-x-auto">
                    {result?.paymentStages?.map((stage, index) => (
                      <div
                        key={`stage-${index}`}
                        className={cn(
                          "flex-start animate__animated animate__fadeInDown flex items-stretch gap-6",
                          `custom-delay-${index + 1}`
                        )}
                      >
                        <div
                          className={cn(
                            "relative",
                            index === result?.paymentStages?.length - 1
                              ? ""
                              : "after:absolute after:left-[50%] after:top-[22px] after:h-[calc(100%-22px)] after:w-[1px] after:-translate-x-1/2 after:bg-mediumTurquoise after:content-['']"
                          )}
                        >
                          <div className="relative h-[18px] w-[18px] rounded-full bg-mediumTurquoise"></div>
                        </div>
                        <div className="flex flex-1 flex-col gap-1 pb-6">
                          <Typo
                            tag={ETypoTag.P}
                            variant={ETypoVariant.HEADER_16}
                            color={ETypoColor.TEXT}
                            className="font-bold"
                          >
                            {t(stage.name)}
                          </Typo>
                          <Typo
                            tag={ETypoTag.P}
                            variant={ETypoVariant.HEADER_16}
                            color={ETypoColor.TEXT}
                          >
                            {t(stage.desc)}
                          </Typo>
                          <Typo
                            tag={ETypoTag.P}
                            variant={ETypoVariant.HEADER_16}
                            color={ETypoColor.TEXT}
                          >
                            {t(stage.secondDesc)}
                          </Typo>
                        </div>
                        <div className="w-[120px] lg:w-[90px]">
                          <Typo
                            tag={ETypoTag.P}
                            variant={ETypoVariant.HEADER_16}
                            color={ETypoColor.TEXT}
                            className="whitespace-pre-wrap break-words text-right font-bold"
                          >
                            {formatCurrency(stage.paymentTotal)}
                          </Typo>
                        </div>
                        <div className="w-[120px] lg:w-[90px]">
                          <Typo
                            tag={ETypoTag.P}
                            variant={ETypoVariant.HEADER_16}
                            color={ETypoColor.TEXT}
                            className="whitespace-pre-wrap text-right font-bold"
                          >
                            {formatCurrency(stage.monthlyPayment)}/month
                          </Typo>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MorgageCalculator;
