import { EComparingProjectsProperty } from "../enums/Project";

import { ICategory } from "~/entities/category";

type ConvertFunction<T = any> = (value: T) => string | number;

export const COMPARING_PROJECT_PROPERTIES: Record<
  EComparingProjectsProperty,
  { label: string; format: string; convert?: ConvertFunction }
> = {
  [EComparingProjectsProperty.MARKET_SEGMENT]: {
    label: "marketSegment",
    format: "string",
  },
  [EComparingProjectsProperty.LOCATION]: {
    label: "location",
    format: "string",
  },
  [EComparingProjectsProperty.DEVELOPER]: {
    label: "developer",
    format: "string",
  },
  [EComparingProjectsProperty.PROJECT_CATEGORY]: {
    label: "projectCategory",
    format: "object",
    convert: (value: ICategory) => value?.name || "",
  },
  [EComparingProjectsProperty.TOTAL_UNITS]: {
    label: "totalUnits",
    format: "string",
  },
  [EComparingProjectsProperty.AVAILABLE_UNITS]: {
    label: "availableUnits",
    format: "string",
  },
  [EComparingProjectsProperty.TENSURE]: {
    label: "tenure",
    format: "string",
  },
  [EComparingProjectsProperty.EXPECTED_TOP]: {
    label: "expectedTop",
    format: "datetime",
  },
};

export const MAX_NUM_OF_COMPARED_PROJECTS = 6;
