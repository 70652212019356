export enum EComparingProjectsProperty {
  LOCATION = "location",
  DEVELOPER = "developer",
  AVAILABLE_UNITS = "availableUnits",
  TOTAL_UNITS = "totalUnits",
  EXPECTED_TOP = "expectedTop",
  TENSURE = "tenure",
  MARKET_SEGMENT = "marketSegment",
  PROJECT_CATEGORY = "category",
}
