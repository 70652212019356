import { useEffect, useMemo, useRef, useState } from "react";

import "./RangeSlider.style.css";

import { IRangeSliderPropsProps } from "./RangeSlider.types";
import Typo from "../Typo";

import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

const RangeSlider: React.FC<IRangeSliderPropsProps> = ({
  min,
  max,
  value,
  onChange,
  step = 1,
  className = "",
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isLabelVisible, setIsLabelVisible] = useState<boolean>(false);
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event?.target?.value) || 0;
    onChange(value);
  };
  useEffect(() => {
    if (!ref.current) return;
    const progress = ((value - min) / (max - min)) * 100;
    ref.current.style.background = `linear-gradient(to right, var(--color-color, #172C37) ${progress}%, var(--color-background-page-project, #FFFFFF) ${progress}%)`;
  }, [value, min, max]);

  const tooltipPosLeft = useMemo(() => {
    if (!ref.current || !isLabelVisible) return;
    const inputWidth = ref?.current?.clientWidth || 0;
    const pos = ((value < min ? min : value) - min) / (max - min);
    const thumbwidth = 16;
    const thumbCorrect = thumbwidth * (pos - 0.25) * -1;
    const tooltipPosLeft = Math.round(
      pos * inputWidth - thumbwidth / 4 + thumbCorrect - 20
    );
    return tooltipPosLeft;
  }, [min, max, value, isLabelVisible]);
  return (
    <div className="relative">
      <input
        ref={ref}
        type="range"
        className={cn("custom-range-slider", className)}
        min={min}
        max={max}
        step={step}
        onChange={handleOnChange}
        value={value}
        onMouseEnter={() => setIsLabelVisible(true)}
        onMouseLeave={() => setIsLabelVisible(false)}
      />
      <Typo
        tag={ETypoTag.P}
        variant={ETypoVariant.BODY_TITLE_14}
        color={ETypoColor.TEXT}
        className={cn(
          "absolute -top-6 w-fit min-w-[60px] transform rounded bg-background p-1 text-center transition-transform duration-300",
          !isLabelVisible ? "scale-0" : "scale-100"
        )}
        style={{ left: `${tooltipPosLeft}px` }}
      >
        {value}
      </Typo>
    </div>
  );
};

export default RangeSlider;
