import { StateCreator } from "zustand";

import { IProject } from "~/entities/project";
import { MAX_NUM_OF_COMPARED_PROJECTS } from "~/utilities/constants/projects";
import { Slug } from "~/utilities/enums/Slug";

export type TProjectStoreState = {
  comparedProjects: IProject[];
  relatedProjects: IProject[];
  comparedProjectsUrl: string;
  relatedProjectId: string;
  showComparedProjectsBar: boolean;
  addComparedProjects: (projects: IProject[]) => void;
  addComparedProject: (project: IProject) => void;
  removeComparedProject: (projectId: string) => void;
  clearComparedProjects: () => void;
  updateComparedProjectsUrl: (url: string) => void;
  addRelatedProject: (project: IProject) => void;
  updateRelatedProjectId: (projectId: string) => void;
  updateShowComparedProjectsBar: (value: boolean) => void;
};

export const createProjectState: StateCreator<
  TProjectStoreState,
  [],
  [],
  TProjectStoreState
> = (set) => ({
  comparedProjects: [],
  relatedProjects: [],
  comparedProjectsUrl: Slug.HOME,
  relatedProjectId: "",
  showComparedProjectsBar: true,
  addComparedProject: (project) =>
    set((state) => {
      if (
        state.comparedProjects.length >= MAX_NUM_OF_COMPARED_PROJECTS ||
        state.comparedProjects.find((p) => p.id === project.id)
      ) {
        return {
          comparedProjects: [...state.comparedProjects],
          showComparedProjectsBar: true,
        };
      }
      return {
        comparedProjects: [...state.comparedProjects, project],
        showComparedProjectsBar: true,
      };
    }),
  addRelatedProject: (project) =>
    set((state) => {
      if (state.relatedProjects.find((p) => p.id === project.id)) {
        return {
          relatedProjects: [...state.relatedProjects],
        };
      }
      return {
        relatedProjects: [...state.relatedProjects, project],
      };
    }),
  addComparedProjects: (projects) =>
    set((state) => {
      return {
        comparedProjects: [...projects],
        showComparedProjectsBar: true,
      };
    }),
  removeComparedProject: (projectId) =>
    set((state) => ({
      comparedProjects: [
        ...state.comparedProjects.filter((project) => project.id !== projectId),
      ],
    })),
  clearComparedProjects: () => set({ comparedProjects: [] }),
  updateComparedProjectsUrl: (url) =>
    set((state) => {
      return {
        comparedProjectsUrl: url,
      };
    }),
  updateRelatedProjectId: (projectId) =>
    set((state) => {
      return {
        relatedProjectId: projectId,
      };
    }),
  updateShowComparedProjectsBar: (value) =>
    set((state) => {
      return {
        showComparedProjectsBar: value,
      };
    }),
});
