import { PAYMENT_STAGES } from "../constants/mortgage";

import { TPaymentStage } from "~/entities/mortgage";

export const calculateLoanDetails = (
  loanAmount: number,
  annualInterestRate: number,
  loanTermMonths: number,
  tdsrPercentage: number,
  msrPercentage: number
): {
  monthlyPayment: number;
  totalPayment: number;
  totalInterest: number;
  totalPrincipal: number;
  totalInterestPercentage: number;
  totalPrincipalPercentage: number;
  firstMonthInterest: number;
  firstMonthPrincipal: number;
  firstMonthInterestPercentage: number;
  firstMonthPrincipalPercentage: number;
  incomeTDSR: number;
  incomeMSR: number;
  pledgedAdditionalPrincipal: number;
  unpledgedAdditionalPrincipal: number;
} => {
  // Validate inputs
  if (
    !isFinite(loanAmount) ||
    loanAmount <= 0 ||
    !isFinite(annualInterestRate) ||
    annualInterestRate <= 0 ||
    !isFinite(loanTermMonths) ||
    loanTermMonths <= 0 ||
    !isFinite(tdsrPercentage) ||
    tdsrPercentage <= 0 ||
    !isFinite(msrPercentage) ||
    msrPercentage <= 0
  ) {
    // Return default values or handle error scenario
    return {
      monthlyPayment: 0,
      totalPayment: 0,
      totalInterest: 0,
      totalPrincipal: 0,
      totalInterestPercentage: 50,
      totalPrincipalPercentage: 50,
      firstMonthInterest: 0,
      firstMonthPrincipal: 0,
      firstMonthInterestPercentage: 50,
      firstMonthPrincipalPercentage: 50,
      incomeTDSR: 0,
      incomeMSR: 0,
      pledgedAdditionalPrincipal: 4800,
      unpledgedAdditionalPrincipal: 16000,
    };
  }

  // Calculate monthly interest rate
  const monthlyInterestRate = annualInterestRate / 12 / 100;

  // Calculate monthly payment (M)
  const monthlyPayment =
    monthlyInterestRate > 0
      ? (loanAmount *
          (monthlyInterestRate *
            Math.pow(1 + monthlyInterestRate, loanTermMonths))) /
        (Math.pow(1 + monthlyInterestRate, loanTermMonths) - 1)
      : 0;

  // Calculate total payment
  const totalPayment = monthlyPayment * loanTermMonths;

  // Calculate total interest
  const totalInterest = totalPayment - loanAmount;

  // Calculate interest and principal for the first payment
  const firstMonthInterest = loanAmount * monthlyInterestRate;
  const firstMonthPrincipal = monthlyPayment - firstMonthInterest;

  // Calculate percentages of first month's interest and principal
  const firstMonthInterestPercentage =
    (firstMonthInterest / monthlyPayment) * 100;
  const firstMonthPrincipalPercentage =
    (firstMonthPrincipal / monthlyPayment) * 100;

  // Calculate percentages of total interest and total principal
  const totalInterestPercentage = (totalInterest / totalPayment) * 100;
  const totalPrincipalPercentage = (loanAmount / totalPayment) * 100;

  // Calculate TDSR and MSR
  const incomeTDSR = monthlyPayment / (tdsrPercentage / 100);
  const incomeMSR = monthlyPayment / (msrPercentage / 100);

  // Calculate additional principal needed for shortfall

  // Pledged scenario: Assume pledged assets lower the risk, therefore the interest rate might be lower.
  // Here, we're assuming the interest rate remains the same for simplicity.
  const pledgedAdditionalPrincipal = 4800;

  // Unpledged scenario: Assume higher risk, the interest rate might be higher.
  // Here, we're assuming the interest rate remains the same for simplicity.
  const unpledgedAdditionalPrincipal = 16000;

  // Return all results

  return {
    monthlyPayment: Number(monthlyPayment.toFixed(0)),
    totalPayment: Number(totalPayment.toFixed(0)),
    totalInterest: Number(totalInterest.toFixed(0)),
    totalPrincipal: Number(loanAmount.toFixed(0)),
    totalInterestPercentage: Number(totalInterestPercentage.toFixed(1)),
    totalPrincipalPercentage: Number(totalPrincipalPercentage.toFixed(1)),
    firstMonthInterest: Number(firstMonthInterest.toFixed(0)),
    firstMonthPrincipal: Number(firstMonthPrincipal.toFixed(0)),
    firstMonthInterestPercentage: Number(
      firstMonthInterestPercentage.toFixed(1)
    ),
    firstMonthPrincipalPercentage: Number(
      firstMonthPrincipalPercentage.toFixed(1)
    ),
    incomeTDSR: Number(incomeTDSR.toFixed(0)),
    incomeMSR: Number(incomeMSR.toFixed(0)),
    pledgedAdditionalPrincipal: Number(pledgedAdditionalPrincipal.toFixed(0)),
    unpledgedAdditionalPrincipal: Number(
      unpledgedAdditionalPrincipal.toFixed(0)
    ),
  };
};

export const calculateBSD = (price: number) => {
  if (price === 0) return 0;
  let feePercent = 0;
  let minusFee = 0;
  if (price <= 1000000) {
    feePercent = 3;
    minusFee = 5400;
  } else if (price >= 1000001 && price <= 1500000) {
    feePercent = 4;
    minusFee = 15400;
  } else if (price >= 1000001 && price <= 1500000) {
    feePercent = 5;
    minusFee = 30400;
  } else if (price > 3000000) {
    feePercent = 6;
    minusFee = 60400;
  }
  const bsd = Number(((price * feePercent) / 100 - minusFee).toFixed(2));
  return bsd > 0 ? bsd : 0;
};

export const getPaymentStages = (
  price: number,
  annualInterestRate: number,
  tenure: number
): TPaymentStage[] => {
  let temp = [...PAYMENT_STAGES];
  temp = temp.map((stage) => {
    const paymentTotal =
      Number(((price * stage.loanPercent) / 100).toFixed(2)) || 0;
    const monthlyPayment = 0;
    return {
      ...stage,
      paymentTotal,
      monthlyPayment,
    };
  });
  temp = temp.map((stage, index, stages) => {
    if (index > 0) {
      return {
        ...stage,
        paymentTotal: stages
          .slice(0, index + 1)
          .reduce((acc, s) => acc + s.paymentTotal, 0),
      };
    }
    return { ...stage };
  });
  temp = temp.map((stage) => {
    const monthlyPayment =
      tenure > 0
        ? Math.round(
            Number(
              (
                stage.paymentTotal / tenure / 12 +
                (stage.paymentTotal * (annualInterestRate / 12)) / 100
              ).toFixed(2)
            )
          )
        : 0;
    return {
      ...stage,
      monthlyPayment,
    };
  });
  return temp;
};
