import { EHousingLoan, EPropertiesOwned, EResidency } from "../enums/mortgage";

import { TPaymentStage } from "~/entities/mortgage";

export const TDSR_PERCENTAGE = 55;
export const MSR_PERCENTAGE = 30;
export const SHORTFALL = 100;
export const MIN_LTV = 0;
export const MAX_LTV = 75;
export const MIN_INTERESTED_RATE = 1;
export const MAX_INTERESTED_RATE = 5;
export const MIN_TENURE = 10;
export const MAX_TENURE = 30;

export const RESIDENCY: Record<
  EResidency,
  { label: string; additionalBSD: Record<EPropertiesOwned, number> }
> = {
  [EResidency.SINGAPOREAN]: {
    label: "singaporean",
    additionalBSD: {
      [EPropertiesOwned["ZERO"]]: 0,
      [EPropertiesOwned["ONE"]]: 20,
      [EPropertiesOwned["GREATER_THAN_TWO"]]: 30,
    },
  },
  [EResidency.PERMANENT_RESIDENT]: {
    label: "permanent_resident",
    additionalBSD: {
      [EPropertiesOwned["ZERO"]]: 5,
      [EPropertiesOwned["ONE"]]: 30,
      [EPropertiesOwned["GREATER_THAN_TWO"]]: 35,
    },
  },
  [EResidency.FOREIGNER]: {
    label: "foreigner",
    additionalBSD: {
      [EPropertiesOwned["ZERO"]]: 60,
      [EPropertiesOwned["ONE"]]: 60,
      [EPropertiesOwned["GREATER_THAN_TWO"]]: 60,
    },
  },
  [EResidency.CORPORATE_ENTITY]: {
    label: "corporate_entity",
    additionalBSD: {
      [EPropertiesOwned["ZERO"]]: 65,
      [EPropertiesOwned["ONE"]]: 65,
      [EPropertiesOwned["GREATER_THAN_TWO"]]: 65,
    },
  },
};

export const PROPERTIES_OWNED: Record<
  EPropertiesOwned,
  { label: string; bookingFee: number; exerciseFee: number }
> = {
  [EPropertiesOwned.ZERO]: {
    label: "0",
    bookingFee: 5,
    exerciseFee: 15,
  },
  [EPropertiesOwned.ONE]: {
    label: "1",
    bookingFee: 5,
    exerciseFee: 15,
  },
  [EPropertiesOwned.GREATER_THAN_TWO]: {
    label: ">=2",
    bookingFee: 5,
    exerciseFee: 15,
  },
};

export const HOUSING_LOAN: Record<
  EHousingLoan,
  { label: string; ltv: number }
> = {
  [EHousingLoan.ZERO]: {
    label: "0",
    ltv: 75,
  },
  [EHousingLoan.ONE]: {
    label: "1",
    ltv: 45,
  },
  [EHousingLoan.GREATER_THAN_TWO]: {
    label: ">=2",
    ltv: 35,
  },
};

export const PAYMENT_STAGES: TPaymentStage[] = [
  {
    name: "mortgage_calculator.payment_state.stage1.name",
    desc: "mortgage_calculator.payment_state.stage1.desc",
    secondDesc: "mortgage_calculator.payment_state.stage1.secondDesc",
    loanPercent: 5,
    paymentTotal: 0,
    monthlyPayment: 0,
  },
  {
    name: "mortgage_calculator.payment_state.stage2.name",
    desc: "mortgage_calculator.payment_state.stage2.desc",
    secondDesc: "mortgage_calculator.payment_state.stage2.secondDesc",
    loanPercent: 10,
    paymentTotal: 0,
    monthlyPayment: 0,
  },
  {
    name: "mortgage_calculator.payment_state.stage3.name",
    desc: "mortgage_calculator.payment_state.stage3.desc",
    secondDesc: "mortgage_calculator.payment_state.stage3.secondDesc",
    loanPercent: 5,
    paymentTotal: 0,
    monthlyPayment: 0,
  },
  {
    name: "mortgage_calculator.payment_state.stage4.name",
    desc: "mortgage_calculator.payment_state.stage4.desc",
    secondDesc: "mortgage_calculator.payment_state.stage4.secondDesc",
    loanPercent: 5,
    paymentTotal: 0,
    monthlyPayment: 0,
  },
  {
    name: "mortgage_calculator.payment_state.stage5.name",
    desc: "mortgage_calculator.payment_state.stage5.desc",
    secondDesc: "mortgage_calculator.payment_state.stage5.secondDesc",
    loanPercent: 5,
    paymentTotal: 0,
    monthlyPayment: 0,
  },
  {
    name: "mortgage_calculator.payment_state.stage6.name",
    desc: "mortgage_calculator.payment_state.stage6.desc",
    secondDesc: "mortgage_calculator.payment_state.stage6.secondDesc",
    loanPercent: 5,
    paymentTotal: 0,
    monthlyPayment: 0,
  },
  {
    name: "mortgage_calculator.payment_state.stage7.name",
    desc: "mortgage_calculator.payment_state.stage7.desc",
    secondDesc: "mortgage_calculator.payment_state.stage7.secondDesc",
    loanPercent: 25,
    paymentTotal: 0,
    monthlyPayment: 0,
  },
  {
    name: "mortgage_calculator.payment_state.stage8.name",
    desc: "mortgage_calculator.payment_state.stage8.desc",
    secondDesc: "mortgage_calculator.payment_state.stage8.secondDesc",
    loanPercent: 15,
    paymentTotal: 0,
    monthlyPayment: 0,
  },
];
