import React, { memo, useEffect, useRef } from "react";
import { useCountUp } from "react-countup";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import "./styles.css";

import Typo from "~/components/atoms/Typo";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
interface IPaymentSummaryProps {
  totalInitial: number;
}
const PaymentSummary: React.FC<IPaymentSummaryProps> = ({ totalInitial }) => {
  const { t } = useTranslation();
  const countUpRef = useRef<HTMLSpanElement>(null);
  const isMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const { start } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: totalInitial || 0,
    delay: 0,
    duration: 1,
  });
  useEffect(() => {
    start();
  }, [start, totalInitial]);

  return (
    <div
      className="pie animate mx-auto flex flex-col items-center justify-center gap-1"
      style={
        {
          "--p": 100,
          "--c": "var(--color-mediumTurquoise, #47CFD6)",
          "--w": isMobile ? "200px" : "264px",
        } as React.CSSProperties
      }
    >
      <Typo
        tag={ETypoTag.P}
        variant={ETypoVariant.HEADER_36}
        color={ETypoColor.TEXT}
        className={cn(
          "whitespace-pre-wrap break-words text-center font-bold",
          isMobile ? "max-w-[156px]" : "max-w-[200px]"
        )}
      >
        $<span ref={countUpRef}>{countUpRef?.current?.innerText || 0}</span>
      </Typo>
      <Typo
        tag={ETypoTag.P}
        variant={ETypoVariant.BODY_TITLE_16}
        color={ETypoColor.TEXT}
        className="text-center"
      >
        {t("mortgage_calculator.total_initial")}
      </Typo>
    </div>
  );
};

export default memo(PaymentSummary);
