import { StateCreator } from "zustand";

export interface TLayoutState {
    bannerHeight: number;
    headerHeight: number;
    setBannerHeight: (height: number) => void;
    setHeaderHeight: (height: number) => void;
}

export const createLayoutState: StateCreator<
    TLayoutState,
    [],
    [],
    TLayoutState
> = (set) => ({
    bannerHeight: 0,
    headerHeight: 0,
    setBannerHeight: (height) =>
        set((state) => ({
            ...state,
            bannerHeight: height,
        })),
    setHeaderHeight: (height) =>
        set((state) => ({
            ...state,
            headerHeight: height,
        })),
});
