import { StateCreator } from "zustand";

export type TAlertStoreState = {
  alertOpen: boolean;
  alertMessage: string;
  setAlert: (alertOpen: boolean, alertMessage: string) => void;
};

export const createAlertState: StateCreator<
  TAlertStoreState,
  [],
  [],
  TAlertStoreState
> = (set) => ({
  alertOpen: false,
  alertMessage: "",
  setAlert: (alertOpen, alertMessage) =>
    set((state) => {
      return {
        alertOpen,
        alertMessage,
      };
    }),
});
