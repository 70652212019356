import { StateCreator } from "zustand";

export type TMortgageRateStoreState = {
  selectedMortgageRate: number;
  selectedMortgageRateId: string;
  selectedMortgageRateYear: string;
  selectMortgageRate: (rate: number, rateId: string, rateYear: string) => void;
};

export const createMortgageRateState: StateCreator<
  TMortgageRateStoreState,
  [],
  [],
  TMortgageRateStoreState
> = (set) => ({
  selectedMortgageRate: 3.8,
  selectedMortgageRateId: "",
  selectedMortgageRateYear: "",
  selectMortgageRate: (rate, rateId, rateYeader) =>
    set((state) => {
      return {
        selectedMortgageRate: rate,
        selectedMortgageRateId: rateId,
        selectedMortgageRateYear: rateYeader,
      };
    }),
});
